<template>
  <div class="login-wrapper">
    <div
      :style="{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundImage ? '#fff' : '',
        'background-size': 'cover',
      }"
      ref="img"
      class="background-img"
    >
      <div class="image-text-box">
        <p class="WebsiteFullName" v-if="websiteFullName">
          {{ websiteFullName }}<br />
          <span class="WebsiteShortName">
            {{ websiteShortName }}
          </span>
        </p>
      </div>
      <div class="description">
        <p>{{ getRes("CustMenu.Com.Action.StreamlineYourworkforce") }}</p>
        <p>{{ getRes("CustMenu.Com.Action.ManagementForAgileOperations") }}</p>
      </div>
      <div class="top-block"></div>
      <div class="bottom-block"></div>
    </div>

    <div class="content">
      <div style="margin: 10px; display: inline-block; height: 25px">
        <div
          style="float: right; display: flex"
          v-if="languageOption.length > 1"
        >
          <div v-for="(item, index) in this.loginPage" :key="item.index">
            <router-link
              :to="item.path"
              :class="item.path !== $route.path ? 'login-link-no-active' : ''"
              >{{ item.name }}</router-link
            >
            <span
              v-if="loginPage.length - 1 !== index"
              style="padding-right: 5px; padding-left: 5px"
              >|</span
            >
          </div>
          <span style="padding-right: 35px"></span>
          <div
            v-for="(language, index) in languageOption"
            :key="index"
            :class="
              languageOptionSelect.value === language.value
                ? 'language-link'
                : 'language-link language-link-no-active'
            "
          >
            <span class="label" @click="languageChange(language)">
              {{ language.label }}
            </span>
            <span
              v-if="index !== languageOption.length - 1"
              style="padding-right: 5px; padding-left: 5px"
              >|</span
            >
          </div>
        </div>
      </div>
      <div class="title">
        <div class="logo" style="width: 300px; overflow: hidden; height: 60px">
          <img :src="logoImage" alt style="height: 60px" />
        </div>
      </div>
      <div class="main">
        <slot />
      </div>
      <div class="footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProLoginLayout",
  data() {
    return {
      backgroundImage: "",
      logoImage: "./static/image/desktop-login/cic-logo.png",
      language: "",
      imgHeight: 0,
      languageOption: [],
      languageOptionSelect: "",
      optionMapping: {
        English: {
          value: "English",
          label: "EN",
          key: "EN",
        },
        Chinese: {
          value: "Chinese",
          label: "简",
          key: "CN",
        },
        TraditionalChinese: {
          value: "TraditionalChinese",
          label: "繁",
          key: "TN",
        },
      },
      loginPage: [
        // {
        //   name: this.getRes("Form.Field.Guest"),
        //   path: "/GuestPortal/OpenDocumentList",
        // },
        {
          name: this.getRes("menu.Supplier"),
          path: "/SupplierLogin",
        },
        {
          name: this.getRes("Form.Field.tenderer"),
          path: "/TendererLogin",
        },
        {
          name: this.getRes("Form.field.procurerPortal"),
          path: "/procurerlogin",
        },
      ],
      websiteFullName: "",
      websiteShortName: "",
    };
  },
  created() {
    this.checkLanguage();

    Promise.all([
      this.$proSmart.setting.getCommonLoginSetting(this),
      this.$proSmart.setting.getWebsiteInfoSetting(this),
    ]).then(([commonLoginSetting, webSiteName]) => {
      for (let i = 0; i < commonLoginSetting.languageOption.length; i++) {
        this.languageOption.push(
          this.optionMapping[commonLoginSetting.languageOption[i]]
        );
      }

      let checkLanguage = this.languageOption.filter(
        (b) => b.value === this.languageOptionSelect.value
      );
      if (checkLanguage.length === 0) {
        this.languageOptionSelect = this.languageOption[0];
        this.languageChange(this.languageOption[0]);
      }

      this.getWebSiteName(webSiteName);
    });
  },
  methods: {
    getWebSiteName(webSiteName) {
      this.websiteFullName =
        webSiteName.websiteFullName[this.languageOptionSelect.key];
      this.websiteShortName = JSON.parse(webSiteName.websiteShortName)[
        this.languageOptionSelect.key
      ];
    },
    checkLanguage() {
      const Language = localStorage.getItem("Language");

      if (Language === "Chinese") {
        this.language = "Chinese";
      } else if (Language === "English") {
        this.language = "English";
      } else if (Language === "TraditionalChinese") {
        this.language = "TraditionalChinese";
      } else {
        this.language = "English";
      }

      this.$emit("ChangeLanguage", this.language);
      this.languageOptionSelect = this.optionMapping[Language];
    },

    languageChange(language) {
      localStorage.setItem("Language", language.value);
      window.Lark.ServiceAjax.setInvokeAttr({
        Language: language.value,
      });
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/quasar.variables";

.login-link-no-active {
  color: $primary;
  text-decoration: underline;
}

.login-wrapper .background-img {
  width: 100%;
  height: 100%;
  z-index: -10;
  zoom: 1;
  min-height: 500px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  background-color: #0066b3;
  overflow: hidden;
  background-position: center;
}

.login-wrapper {
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
}

.login-wrapper .content {
  box-sizing: border-box;
  padding: 0% 3% 3% 3%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  min-width: 640px;
}

@media screen and (max-width: 1080px) {
  .login-wrapper .content {
    min-width: 400px;
    width: 400px;
  }
}

@media screen and (min-width: 1081px) {
  .login-wrapper .content {
    min-width: 640px;
    width: 640px;
  }
}

.login-wrapper .content .main {
  margin-top: 10%;
  margin-left: 1%;
}

.login-wrapper .content .main .login-font {
  /*width: 97px;*/
  height: 44px;
  /*font-family: BeVietnam;*/
  font-size: 25px;
  font-weight: 600;
  margin: auto 0;
}

.login-wrapper .content .main .login-form .form-item .label-font {
  display: inline-block;
  margin-bottom: 6px;
  line-height: 23px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}

.login-wrapper .content .main .login-form {
  margin-top: 25px;
  width: 100%;
}

.login-wrapper .content .footer {
  margin-top: auto;
}

.language-link {
  display: flex;
}

.language-link .label {
  cursor: pointer;
}

.language-link-no-active .label {
  color: $primary;
  text-decoration: underline;
}

.image-text-box {
  margin-top: 8.4%;
  margin-left: 6.4%;
  width: 32.8vw;
  height: 29.93vh;
  text-align: left;
  opacity: 1;

  .WebsiteFullName {
    color: #fff;
    font-size: 4.5vw;
  }

  .WebsiteShortName {
    //font-size: 2.1vw;
    //color: $primary;
    color: #fff;
    font-size: 4.5vw;
  }
}
</style>
